body {
  margin: 0;
}
#root {
  width: 100%;
  min-height: 100vh;
  /* background: radial-gradient(circle at 0 0, rgba(197, 255, 3, 1) 10%, rgba(197, 255, 3, 0.3)),
              radial-gradient(circle at top right, rgba(237, 17, 70, 1) 10%, rgba(237, 17, 70, 0.2)),
              linear-gradient(90deg, rgba(3, 166, 105, 1) 50%, rgba(3, 166, 105, 0.3)); */
  background: #f4fae3;
  background-attachment: fixed;
}
